import React from "react";

function TaskResult({ task }) {
  return (
    <div className="mb-4 last:mb-0">
      <div className="flex items-center justify-between mb-2">
        <h4 className="font-semibold">
          Task {task.taskNumber}: {task.taskDescription}
        </h4>
        <span className="text-sm">{task.markAwarded} marks</span>
      </div>
      <p className="text-sm text-foreground/80 mb-2">{task.feedback}</p>
      <div
        className={`text-xs px-2 py-1 rounded-full inline-block ${
          task.lineStatus === "Code Correct"
            ? "bg-green-100 text-green-800"
            : "bg-red-100 text-red-800"
        }`}
      >
        {task.lineStatus}
      </div>
    </div>
  );
}

export default function EvaluationResults({ result }) {
  return (
    <div className="mt-6 border rounded-lg p-6 bg-background/50">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold">Evaluation Results</h3>
        <span className="text-xl font-bold">{result.totalMark} marks</span>
      </div>

      <div className="mb-6">
        {result.taskEvaluations.map((task) => (
          <TaskResult key={task.taskNumber} task={task} />
        ))}
      </div>

      {result.suggestions.length > 0 && (
        <div className="mb-6">
          <h4 className="font-semibold mb-2">Suggestions for Improvement</h4>
          <ul className="list-disc list-inside text-sm text-foreground/80">
            {result.suggestions.map((suggestion, index) => (
              <li key={index}>{suggestion}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="text-sm text-foreground/80">
        <strong>Overall: </strong>
        {result.overallComment}
      </div>
    </div>
  );
}
