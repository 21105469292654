// src/components/TeacherDashboard.js
import React, { useEffect, useState } from "react";
import questions from "../data/questions";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog";
import { Copy, Check, HelpCircle, Info, ExternalLink } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import temperatureTable from "../assets/temperature_table.png";
import logo from "../assets/avatar4.png"
import axios from "axios"; // If using Axios
import TextCopyAcknowledgement from "./TextCopyAcknowledgement";

const TeacherDashboard = () => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [copied, setCopied] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedQuestionDetails, setSelectedQuestionDetails] = useState(null);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state
  const [copyAcknnowledgement, setCopyAcknowledgement] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const toggleQuestion = (questionId) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected.includes(questionId) ? prevSelected.filter((q) => q !== questionId) : [...prevSelected, questionId]
    );
  };

  const generateLink = async () => {
    // Validate the email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    setEmailError("");

    // Set loading state
    setLoading(true);

    // Create query params for selected questions
    const params = selectedQuestions.map((q) => `${q}=true`).join("&");
    const link = `${window.location.origin}/assessment?${params}&email=${encodeURIComponent(email)}`;
    console.log("Generated Link:", link);

    try {
      // Correct the API URL for the backend
      const response = await axios.post("https://staging.teepee.ai/teepee_web_server_test/edexel_email_notifier", {
        body: {
          email: email,
          questions: selectedQuestions,
        },
      });

      // Handle response based on status
      if (response.data.status === "success") {
        console.log("Email sent successfully:", response.data.message);
      } else {
        // Handle failure
        setEmailError(response.data.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setEmailError("Failed to generate link. Please try again later.");
    }

    // Reset loading state and show the generated link
    setLoading(false);
    setGeneratedLink(link);
    setIsLinkDialogOpen(true);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(generatedLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const showQuestionDetails = (question) => {
    setSelectedQuestionDetails(question);
    setIsDetailsDialogOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = () => {
    const url = "https://app.teepee.ai/"; // Replace this with your target URL
    window.open(url, "_blank");
  };

  return (
    <div className="container  max-w-screen-2xl min-h-screen">
      <nav className="sticky top-0 z-10 bg-white py-5 shadow-sm">
        <div className="max-w-8xl mx-auto px-6 flex items-center justify-between  ml-10">
          <div className="flex items-center">
            {/* Logo */}
            <a href="https://teepee.ai" target="_blank">
              <img
                src={logo} // Path to the logo in the public folder
                alt="Teepee.ai Logo"
                width={200} // Adjust the width as needed
                height={40} // Adjust the height as needed
                className="cursor-pointer"
              />
            </a>
          </div>

          <div className="flex items-center gap-10">
            <a
              href="https://teepee.ai/"
              className="text-gray-700 hover:text-gray-900 text-[16px] font-poppins"
              target="_blank"
            >
              Home
            </a>

            <div className="flex items-center gap-2">
              <a
                href="https://app.teepee.ai/"
                className="text-[#6750A4] hover:text-purple-700 text-[16px] font-poppins"
                target="_blank"
              >
                Unlimited Exam Practice, Instant Feedback
              </a>
              <span className="text-purple-600">→</span>
            </div>

            <button
              onClick={handleClick}
              className="bg-[#6750A4] text-white px-4 py-2 rounded-[48px] text-[16px] font-poppins font-medium hover:bg-purple-700"
            >
              Try for free
            </button>
          </div>
        </div>
      </nav>

            {copyAcknnowledgement && <TextCopyAcknowledgement />}
      
      <div className="p-8">

      <div className="mb-6 mt-8">
        <h1 className="text-2xl font-bold text-primary">Edexcel P2 Programming Assessment & Practice Tool by <a href="https://teepee.ai" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              Teepee.ai
            </a></h1>
        <div className="flex items-center mt-2 space-x-2">
          <h2 className="text-lg text-gray-600">Set up your assessment</h2>
          <button onClick={() => setIsHelpDialogOpen(true)} className="inline-flex items-center text-blue-600 hover:text-blue-800">
            <HelpCircle className="h-4 w-4 mr-1" />
            How does this work?
          </button>
        </div>
      </div>

      <div className="mb-6">
        <div className="mb-8 p-4 bg-blue-50 border-2 border-blue-200 rounded-lg">
          <label htmlFor="teacherEmail" className="block text-lg font-semibold text-blue-800 mb-2">
            Teacher's Email Address *
          </label>
          <Input
            id="teacherEmail"
            type="email"
            value={email}
            maxLength={65} // Lim
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            placeholder="Enter your email address"
            className={emailError ? "border-red-500" : "w-full max-w-md border-blue-200 focus:border-blue-500"}
            required
          />
          {!email && (
            <p className="mt-2 text-amber-600">
              <span className="inline-block mr-2">⚠️</span>
              Please enter your email to receive student results
            </p>
          )}
        </div>
        {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Select Questions to Generate an Assessment Link</CardTitle>
          <CardDescription>Choose the questions you want to include in the assessment</CardDescription>
        </CardHeader>
        <CardContent>
          <ScrollArea className="rounded-md border p-4">
            <div className="space-y-4">
              {questions.map((question) => (
                <Card key={question.id} className="p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id={question.id}
                        onChange={() => toggleQuestion(question.id)}
                        checked={selectedQuestions.includes(question.id)}
                        className="h-4 w-4"
                      />
                      <Label htmlFor={question.id} className="flex-1">
                        <div className="font-medium">
                          Question {question.id.toUpperCase()} - {question.summary}
                        </div>
                        <div className="text-sm text-gray-500">({question.marks} Marks)</div>
                      </Label>
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => showQuestionDetails(question)}
                      className="text-blue-600 hover:text-blue-800 flex items-center space-x-1"
                    >
                      <span>Show details</span>
                      <ExternalLink className="h-4 w-4" />
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          </ScrollArea>

          <div className="mt-6 flex justify-end">
            <Button onClick={generateLink} disabled={loading || selectedQuestions.length === 0 || !validateEmail(email)}>
              {loading ? "Generating..." : "Generate Link"}
            </Button>
          </div>
        </CardContent>
      </Card>

      <Dialog open={isLinkDialogOpen} onOpenChange={setIsLinkDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Generated Assessment Link</DialogTitle>
          </DialogHeader>
          <div className="bg-muted p-4 rounded-md">
            <code className="break-all">{generatedLink}</code>
          </div>
          <DialogFooter>
            <Button
              onClick={async () => {
                setCopyAcknowledgement(true);
                await copyToClipboard();
                setIsLinkDialogOpen(false);
                setTimeout(() => {
                  setCopyAcknowledgement(false);
                }, 2000);

              }}
            >
              Copy and Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isHelpDialogOpen} onOpenChange={setIsHelpDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>How to Use This Tool</DialogTitle>
          </DialogHeader>

          <div className="space-y-4">
            <div>
              <h3 className="font-semibold mb-2">For Teachers:</h3>
              <ol className="list-decimal list-inside space-y-2">
                <li>Select the programming questions you want to include in the assessment</li>
                <li>Click "Generate Link" to create a unique assessment URL</li>
                <li>Share the generated link with your students</li>
                <li>Each student's work will be automatically marked when submitted</li>
              </ol>
            </div>

            <div>
              <h3 className="font-semibold mb-2">For Students:</h3>
              <ol className="list-decimal list-inside space-y-2">
                <li>Click the assessment link provided by your teacher</li>
                <li>Read each question carefully and write your code in the editor</li>
                <li>Test your code before submitting</li>
                <li>Click "Submit for Marking" when you're ready</li>
                <li>Review your feedback and marks immediately after submission</li>
              </ol>
            </div>

            <div>
              <h3 className="font-semibold mb-2">Features:</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Automatic marking aligned with Edexcel P2 criteria</li>
                <li>Immediate feedback for students</li>
                <li>Support for multiple programming tasks</li>
                <li>Code editor with syntax highlighting</li>
              </ul>
            </div>
          </div>

          <DialogFooter>
            <Button onClick={() => setIsHelpDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isDetailsDialogOpen} onOpenChange={setIsDetailsDialogOpen}>
        <DialogContent className="max-w-4xl max-h-[80vh]">
          <DialogHeader>
            <DialogTitle>Question {selectedQuestionDetails?.id.toUpperCase()} Details</DialogTitle>
          </DialogHeader>

          {selectedQuestionDetails && (
            <ScrollArea className="h-[calc(80vh-8rem)] pr-4">
              <div className="space-y-6">
                {/* Summary and Marks */}
                <div>
                  <h3 className="font-semibold text-lg">{selectedQuestionDetails.summary}</h3>
                  <p className="text-gray-600">Total Marks: {selectedQuestionDetails.marks}</p>
                </div>

                {/* Question Text */}
                <div>
                  <h4 className="font-semibold mb-2">Question Text:</h4>
                  <div className="bg-gray-50 p-4 rounded-md">
                    {selectedQuestionDetails.text.split("\n").map((line, index) => {
                      // Handle bullet points
                      if (line.trim().startsWith("-")) {
                        return (
                          <div key={index} className="ml-4">
                            <span className="inline-block w-2 h-2 rounded-full bg-gray-400 mr-2" />
                            {line.trim().substring(1)}
                          </div>
                        );
                      }
                      // Handle section headers (like "Task")
                      else if (line.trim() && !line.trim().startsWith("[")) {
                        return (
                          <p key={index} className="mb-4">
                            {line.trim()}
                          </p>
                        );
                      }
                      // Handle image placeholder
                      else if (line.includes("[Display image:")) {
                        return (
                          <div key={index} className="my-4">
                            <img src={temperatureTable} alt="Question reference" className="max-w-[400px] h-auto rounded-md border border-gray-200" />
                          </div>
                        );
                      }
                      // Return empty div for empty lines to maintain spacing
                      return <div key={index} className="h-4" />;
                    })}
                  </div>
                </div>

                {/* Initial Code */}
                <div>
                  <h4 className="font-semibold mb-2">Initial Code:</h4>
                  <div className="bg-gray-50 p-4 rounded-md">
                    <pre className="text-sm overflow-x-auto font-mono break-words">{selectedQuestionDetails.initialCode}</pre>
                  </div>
                </div>
              </div>
            </ScrollArea>
          )}

          <DialogFooter>
            <Button onClick={() => setIsDetailsDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
    </div>
  );
};

export default TeacherDashboard;
