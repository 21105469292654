import React from 'react';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center p-4">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
  </div>
);

export const LoadingOverlay = ({ message = "Evaluating your code..." }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-6 max-w-sm mx-4 text-center shadow-xl">
      <LoadingSpinner />
      <p className="mt-4 text-gray-700">{message}</p>
      <p className="mt-2 text-sm text-gray-500">(can take up to 1 minute)</p>
    </div>
  </div>
);

export default LoadingOverlay;