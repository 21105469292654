import React, { useState, useMemo, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TeacherDashboard from "./components/TeacherDashboard";
import StudentAssessment from "./components/StudentAssessment";
import FeedbackDisplay from "./components/FeedbackDisplay";
import { evaluateCode } from "./services/openaiService";
import Home from "../src/IGCSE_Tool/src/app/page";
import LandingPage from "../src/components/LandingPage"
import "./App.css";

function App() {
  const initialCode = `# -------------------------------------------------------------------
# Global variables
# -------------------------------------------------------------------
fahrenheit = 0
# =====> Add a line to create an integer variable named 'celsius' and
#        set it to 0

# -------------------------------------------------------------------
# Main program
# -------------------------------------------------------------------
# =====> Complete the line to take the input from the user and
#        convert it to an integer
celsius =

# =====> Complete the if statement to check that the inputted temperature
#        is between -10 and 35.
#        Use two relational operators and one logical operator
if ((          )       (          )):
    # =====> Complete the line to multiply celsius by 2, add 30,
    #        and assign the result to the variable fahrenheit
    fahrenheit
    # =====> Complete the line to join strings together with concatenation
    print (str (celsius)     "°C is approximately "      str(fahrenheit)      "°F")
else:
    # =====> Add a line to display an error message`;

  const [code, setCode] = useState(initialCode);
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const feedbackRef = useRef(null);

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    setIsEdited(newCode !== initialCode && newCode.trim() !== "");
  };

  const handleSubmit = async () => {
    if (!isEdited) return;
    setIsLoading(true);
    try {
      const feedbackData = await evaluateCode(code);
      setFeedback(feedbackData);
    } catch (error) {
      console.error("Error evaluating code:", error);
      setFeedback({
        totalMark: 0,
        markingBreakdown: [],
        suggestions: ["An error occurred. Please try again."],
        overallComment: "Unable to evaluate the code due to an error.",
      });
    }
    setIsLoading(false);
  };

  const { calculatedTotalMark, finalTotalMark } = useMemo(() => {
    if (!feedback) return { calculatedTotalMark: 0, finalTotalMark: 0 };

    const calculatedTotalMark = feedback.markingBreakdown.reduce((total, item) => total + item.markAwarded, 0);

    const finalTotalMark = calculatedTotalMark !== feedback.totalMark ? calculatedTotalMark : feedback.totalMark;

    return { calculatedTotalMark, finalTotalMark };
  }, [feedback]);

  useEffect(() => {
    if (feedback && feedbackRef.current) {
      feedbackRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [feedback]);

  return (
    <Router>
      <Routes>
        {/* Teacher dashboard for selecting questions and generating assessment link */}
        <Route path="/GCSE" element={<TeacherDashboard />} />
        {/* Student assessment page with questions selected by the teacher */}
        <Route path="/assessment" element={<StudentAssessment />} />
        <Route path="/IGCSE" element={<Home />} />
        <Route path="/" element={<LandingPage />} />

      </Routes>
      <div className="app-container">
        <main className="app-main">
          {feedback && (
            <section className="feedback-section" ref={feedbackRef}>
              <h2>Feedback</h2>
              <FeedbackDisplay feedback={feedback} calculatedTotalMark={calculatedTotalMark} finalTotalMark={finalTotalMark} />
            </section>
          )}
          <div className="powered-by">
            Powered by{" "}
            <a href="https://teepee.ai" target="_blank" rel="noopener noreferrer">
              Teepee.ai
            </a>
          </div>
        </main>
      </div>
    </Router>
  );
}

export default App;
