import React from "react";

function TextCopyAcknowledgement() {
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="bg-green-100 border border-green-300 text-green-700 px-4 py-3 rounded-lg shadow-lg flex items-center gap-2">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <span>Assessment link copied to clipboard!</span>
      </div>
    </div>
  );
}

export default TextCopyAcknowledgement;
