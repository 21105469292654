import { OpenAI } from "openai";
import SubmissionFormatService from "./SubmissionFormatService";

export class EvaluationService {
  constructor() {
    this.apiEndpoint = "/api/evaluate"; // This is no longer used but retained for reference
    this.openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY, // Ensure this is set in your .env file
      dangerouslyAllowBrowser: true
    });
  }

  async evaluate(code, config, questionId) {
    console.log("\n=== EVALUATION SERVICE INPUT ===");
    console.log("Raw code:", code);
    console.log("Question ID:", questionId);

    // Format the submission using the SubmissionFormatService
    const formattedSubmission = SubmissionFormatService.formatSubmission(questionId, code);
    console.log("\n=== FORMATTED SUBMISSION ===");
    console.log(JSON.stringify(formattedSubmission, null, 2));

    // Replace placeholders in the user prompt
    const promptContent = config.userPrompt
      .replace(
        "[Insert markingPoints here]",
        JSON.stringify(config.markingPoints, null, 2)
      )
      .replace(
        "[Insert the student code here]",
        JSON.stringify(formattedSubmission, null, 2)
      );

    console.log("\n=== PROMPTS ===");
    console.log("System Prompt:", config.systemPrompt);
    console.log("\nUser Prompt:", promptContent);

    try {
      console.log("\n=== FINAL OPENAI REQUEST ===");
      console.log(
        JSON.stringify(
          {
            model: "gpt-4o-mini",
            messages: [
              {
                role: "system",
                content: config.systemPrompt,
              },
              {
                role: "user",
                content: promptContent,
              },
            ],
          },
          null,
          2
        )
      );

      // Make the OpenAI API call
      const completion = await this.openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: config.systemPrompt,
          },
          {
            role: "user",
            content: promptContent,
          },
        ],
      });

      // Parse the result
      const result = JSON.parse(completion.choices[0].message.content || "{}");

      // Calculate the total marks
      const calculatedTotal = result.taskEvaluations.reduce(
        (sum, task) => sum + task.markAwarded,
        0
      );

      // Override the total marks
      result.totalMark = calculatedTotal;

      console.log("\n=== EVALUATION RESULT ===");
      console.log(JSON.stringify(result, null, 2));
      console.log("Results",result)

      return result; // Return the evaluation result
    } catch (error) {
      console.error("Evaluation failed:", error);
      throw new Error("Failed to evaluate code");
    }
  }
}

export const evaluationService = new EvaluationService();
