"use client";

import Editor from "@monaco-editor/react";
import React from "react";

export default function CodeEditor({ code, onChange, height = "200px", readOnly = false }) {
  return (
    <Editor
      height={height}
      defaultLanguage="python"
      value={code}
      onChange={(value) => onChange(value || "")}
      options={{
        minimap: { enabled: false },
        fontSize: 14,
        lineNumbers: "on",
        readOnly,
        scrollBeyondLastLine: false,
        automaticLayout: true,
      }}
      className="border rounded-md overflow-hidden"
    />
  );
}
