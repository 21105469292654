// openaiService.js
import axios from 'axios';
import { marked } from 'marked';

const API_URL = 'https://api.openai.com/v1/chat/completions';
const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${API_KEY}`
};

const questionConfig = {
  'q1': {
    totalTasks: 10,
    taskTypes: {
      1: "Code Completion",
      2: "Code Completion",
      3: "Code Completion",
      4: "Code Completion",
      5: "Code Completion",
      6: "Code Completion",
      7: "Code Completion",
      8: "Code Completion",
      9: "Code Completion",
      10: "Code Completion"
    },
    descriptions: {
      1: "Global Variable Initialization",
      2: "User Input Prompt",
      3: "Input Conversion to Integer",
      4: "Lower Bound Check",
      5: "Upper Bound Check",
      6: "Logical Operator Usage",
      7: "Temperature Calculation",
      8: "Assignment Operator Usage",
      9: "String Concatenation in Print Statement",
      10: "Invalid Input Message"
    }
  },
  'q2': {
    totalTasks: 8,
    taskTypes: {
      1: "Code Correction",
      2: "Code Correction",
      3: "Code Correction",
      4: "Code Correction",
      5: "Code Correction",
      6: "Code Correction",
      7: "Code Correction",
      8: "Code Correction"
    },
    descriptions: {
      1: "Library Import Correction",
      2: "Array Initialization",
      3: "For Loop Syntax",
      4: "Logic Error Fix",
      5: "Random Number Generation",
      6: "Index Error Fix",
      7: "Name Error Fix",
      8: "White Space Improvement"
    }
  },
  'q3': {
    totalTasks: 15,
    taskTypes: {
      1: "Code Completion",
      2: "Code Completion",
      3: "Code Completion",
      4: "Multiple-Choice Uncommenting",
      5: "Multiple-Choice Uncommenting",
      6: "Multiple-Choice Uncommenting",
      7: "Multiple-Choice Uncommenting",
      8: "Code Completion",
      9: "Multiple-Choice Uncommenting",
      10: "Multiple-Choice Uncommenting",
      11: "Multiple-Choice Uncommenting",
      12: "Multiple-Choice Uncommenting",
      13: "Multiple-Choice Uncommenting",
      14: "Multiple-Choice Uncommenting",
      15: "Multiple-Choice Uncommenting"
    },
    descriptions: {
      1: "Add the correct input file name (Spades.txt)",
      2: "Add the correct extension to this file name (Plants.txt)",
      3: "Complete the line with the correct variable name for the array of plants",
      4: "Choose the correct value to initialize the variable",
      // ... other descriptions remain the same ...
    }
  },
  'q4': {
    totalTasks: 11,
    taskTypes: {
      1: "Code Rearrangement",
      2: "Code Rearrangement",
      3: "Code Rearrangement",
      4: "Code Rearrangement",
      5: "Code Rearrangement",
      6: "Code Rearrangement",
      7: "Code Rearrangement",
      8: "Code Rearrangement",
      9: "Code Rearrangement",
      10: "Code Rearrangement",
      11: "Code Rearrangement"
    },
    descriptions: {
      1: "Initialization of variables in hexaLoop",
      2: "Placement of for loop in hexaLoop",
      3: "Order of calculations in hexaLoop",
      4: "Return statement placement",
      5: "User input before loop",
      6: "While loop placement",
      7: "Subprogram call placement",
      8: "Output statement placement",
      9: "User input at end of loop",
      10: "Test data handling",
      11: "Code indentation"
    }
  },
  'q5': {
    totalTasks: 11,  // 9 specific tasks + 2 level-based tasks
    taskTypes: {
      1: "Code Modification",  // 5.1
      2: "Code Modification",  // 5.2
      3: "Code Modification",  // 5.3
      4: "Code Modification",  // 5.4
      5: "Code Modification",  // 5.5
      6: "Code Modification",  // 5.6
      7: "Code Modification",  // 5.7
      8: "Code Modification",  // 5.8
      9: "Code Modification",  // 5.9
      10: "Solution Design",   // Level-based
      11: "Functionality"      // Level-based
    },
    descriptions: {
      1: "Change one parameter name",
      2: "Change all three parameter names",
      3: "Meaningful parameter names",
      4: "Use ord() for ASCII calculation",
      5: "Define header procedure correctly",
      6: "Appropriate header message",
      7: "Call header before input",
      8: "Convert inputs to uppercase",
      9: "Validate publication year",
      10: "Overall solution design",
      11: "Overall functionality"
    },
    maxScores: {
      1: 1,
      2: 1,
      3: 1,
      4: 1,
      5: 1,
      6: 1,
      7: 1,
      8: 1,
      9: 1,
      10: 1,
      11: 1
    }
  },
  'q6': {
    totalTasks: 15,  // 6 specific criteria + 9 levels-based marks
    taskTypes: {
      1: "Technical Implementation",  // 6.1
      2: "Technical Implementation",  // 6.2
      3: "Technical Implementation",  // 6.3
      4: "Technical Implementation",  // 6.4
      5: "Technical Implementation",  // 6.5
      6: "Technical Implementation",  // 6.6
      7: "Solution Design",          // Level 0-3
      8: "Good Programming Practice", // Level 0-3
      9: "Functionality"             // Level 0-3
    },
    descriptions: {
      1: "Taking Two String Inputs",
      2: "Checking for Blank Input",
      3: "Traversing the userTable Using a Loop",
      4: "Using Logical Operators in Conditions",
      5: "Accessing Individual Fields in Records",
      6: "Distinguishing Between Different States",
      7: "Overall Solution Design",
      8: "Good Programming Practice",
      9: "Overall Functionality"
    },
    maxScores: {
      1: 1,
      2: 1,
      3: 1,
      4: 1,
      5: 1,
      6: 1,
      7: 3,  // Levels-based
      8: 3,  // Levels-based
      9: 3   // Levels-based
    }
  }
};

const TASK_DESCRIPTIONS = {
  1: "Add the correct input file name (Spades.txt)",
  2: "Add the correct extension to this file name (Plants.txt)",
  3: "Complete the line with the correct variable name for the array of plants",
  4: "Choose the correct value to initialize the 'total' variable",
  5: "Choose the correct value to initialize the 'outLine' variable",
  6: "Choose the correct line to open the input file",
  7: "Choose the correct line to locate the substring in the line",
  8: "Complete the line to increment total",
  9: "Choose the correct line to close the input file",
  10: "Choose the correct line to display the output",
  11: "Choose the correct line to open the plants file",
  12: "Choose the correct line to convert the case",
  13: "Choose the correct line to complete the output line",
  14: "Choose the correct line to write to the file",
  15: "Choose the correct line to display the final output"
};

// Add this helper function near the top with other constants
const cleanAIResponse = (content) => {
  // Remove markdown code block if present
  if (content.startsWith('```')) {
    const lines = content.split('\n');
    // Remove first and last lines if they contain ```
    if (lines[0].startsWith('```')) lines.shift();
    if (lines[lines.length - 1].startsWith('```')) lines.pop();
    content = lines.join('\n');
  }
  return content.trim();
};

/**
 * Extracts a specific task section from the code based on start and end markers
 */
const extractTaskSection = (code, taskNum, questionId) => {
  if (!code) return [""];
  
  console.log('Extracting task', taskNum, 'for question', questionId);
  
  if (questionId === 'q2') {
    const lines = code.split('\n');
    switch(taskNum) {
      case 1: // Library Import
        return [lines.find(line => line.trim().startsWith('import')) || ''];
        
      case 2: // Array Initialization
        return [lines.find(line => line.trim().startsWith('mealTable =')) || ''];
        
      case 3: // For Loop Syntax
        return [lines.find(line => line.trim().startsWith('for meal in')) || ''];
        
      case 4: // Range Fix
        const rangeLine = lines.find(line => line.includes('range('));
        console.log('Task 4 Range line found:', rangeLine);
        return rangeLine ? [rangeLine] : [""];
        
      case 5: // Random Number Generation
        return [lines.find(line => line.includes('index = random')) || ''];
        
      case 6: // Index Error Fix
        const indexLine = lines.find(line => line.includes('mealTable['));
        console.log('Task 6 Index line found:', indexLine);
        return indexLine ? [indexLine] : [""];
        
      case 7: // Name Error Fix
        return [lines.find(line => line.includes('print(')) || ''];
        
      case 8: // White Space
        return lines;
        
      default:
        return [""];  // Add default case
    }
  }

  else if (questionId === 'q5') {
    const lines = code.split('\n');
    
    switch(taskNum) {
      case 1:
case 2:
case 3: {
  // Extract the makeIdentifier function definition line
  const funcLine = lines.find(line => 
    line.trim().startsWith('def makeIdentifier'));
  
  if (!funcLine) return [''];
  
  // Extract parameter names
  const match = funcLine.match(/def\s+makeIdentifier\s*\((.*?)\)/);
  if (!match) return [''];
  
  const params = match[1].split(',').map(p => p.trim());
  const originalParams = ['bookTitle', 'authorLastName', 'pubYear'];
  
  // For task 2: Check if all parameters are different
  // For task 2: Check if all parameters are different
if (taskNum === 2) {
  // Compare each parameter with its corresponding original
  const paramChanges = params.map((param, index) => {
    const original = originalParams[index];
    return param !== original;  // Direct comparison
  });
  
  const allDifferent = paramChanges.every(diff => diff);
  console.log('Task 2 parameter check:', {
    params,
    originalParams,
    paramChanges,
    allDifferent
  });

  // Return both the line and the verification result
  return {
    submittedCode: funcLine,
    verificationData: {
      allDifferent,
      changes: paramChanges
    }
  };
}
  
  return [funcLine];
}
      
      case 4: {
        // Extract the calculation line whether it uses int() or ord()
        const calcLine = lines.find(line => 
          line.includes('numberPart') && 
          line.includes('digit') &&
          (line.includes('int(') || line.includes('ord('))
        );
        
        return calcLine ? [calcLine] : [''];
      }
      
      case 5: {
        // Look for header procedure definition
        const headerLine = lines.find(line => 
          line.trim().startsWith('def displayHeader'));
        return headerLine ? [headerLine] : [''];
      }
      
      case 6: {
        // Look for header function and its content
        let headerLines = [];
        let inHeaderFunc = false;
        
        for (const line of lines) {
          const trimmed = line.trim();
          if (trimmed.startsWith('def displayHeader')) {
            inHeaderFunc = true;
            headerLines.push(line);
          } else if (inHeaderFunc) {
            if (trimmed.startsWith('print(')) {
              headerLines.push(line);  // Include any print statement in the function
            } else if (trimmed === '' || !line.startsWith(' ')) {
              // End of function reached (empty line or no indentation)
              break;
            }
          }
        }
        return headerLines.length > 0 ? headerLines : [''];
      }
      
      case 7: {
        // Extract both the header definition and its call
        const headerLines = lines.filter(line => {
          const trimmed = line.trim();
          return (
            // Match the function definition
            trimmed.startsWith('def displayHeader') ||
            // Match the function call
            trimmed === 'displayHeader()' ||
            // Match the first input after the header call
            line.includes('input(')
          );
        });

        // Keep only the relevant sequence of lines
        const firstInputIndex = headerLines.findIndex(line => 
          line.includes('input('));
        
        if (firstInputIndex !== -1) {
          // Return the lines up to and including the first input
          return headerLines.slice(0, firstInputIndex + 1);
        }
        
        return headerLines;
      }
      
      case 8: {
        // Extract all input lines and any lines that might contain uppercase conversion
        const inputLines = lines.filter(line => 
          // Match input lines
          line.includes('bookTitle = input(') || 
          line.includes('authorLastName = input(')
        );
        
        // Also extract any lines that might contain uppercase conversion
        const upperLines = lines.filter(line => {
          const trimmed = line.trim();
          return (
            // Match any line that assigns to these variables and includes .upper()
            (trimmed.startsWith('bookTitle') && line.includes('.upper()')) ||
            (trimmed.startsWith('authorLastName') && line.includes('.upper()'))
          );
        });
        
        // Combine both sets of lines
        return [...inputLines, ...upperLines];
      }
      
      case 9: {
        // Extract only the year validation logic
        const validationLines = lines.filter(line => {
          const trimmed = line.trim();
          return (
            // Match only the validation if statement and its immediate block
            (trimmed.startsWith('if') && 
             line.includes('pubYear') && 
             (line.includes('isdigit()') || line.includes('len'))) ||
            // Match the makeIdentifier call only if it's indented (part of if block)
            (line.includes('makeIdentifier') && line.includes('bookID') && 
             line.startsWith('  '))
          );
        });
        
        return validationLines.length > 0 ? validationLines : [''];
      }
      
      // ... rest of the cases remain the same ...
    }
  }



  else if (questionId === 'q1') {
    const lines = code.split('\n');
    
    // For tasks 4,5,6 (bound checks and 'and'), find the if statement line
    if ([4,5,6].includes(taskNum)) {
      // Modify to find ANY if statement line, even if incomplete
      const ifLine = lines.find(line => line.trim().startsWith('if'));
      console.log(`Task ${taskNum} if line:`, ifLine);
      // Always return the if line for evaluation, even if empty or incomplete
      return ifLine ? [ifLine] : [""];
    }
    
    // For task 7 (formula), look for the complete calculation inside if block
    if (taskNum === 7) {
      const calcLine = lines.find(line => {
        const trimmed = line.trim();
        return trimmed.startsWith('fahrenheit') && 
               trimmed.includes('celsius') && 
               trimmed.includes('* 2');
      });
      console.log(`Task 7 calculation line:`, calcLine);
      return calcLine ? [calcLine] : [""];
    }
    
    // For task 8 (assignment operator), specifically look for = in calculation line inside if block
    if (taskNum === 8) {
      // Find the if statement first
      const ifStartIndex = lines.findIndex(line => line.trim().startsWith('if'));
      if (ifStartIndex !== -1) {
        // Look for fahrenheit assignment only in lines after the if statement
        const calcLine = lines.slice(ifStartIndex).find(line => {
          const trimmed = line.trim();
          return trimmed.startsWith('fahrenheit') && 
                 trimmed.includes('=');
        });
        console.log(`Task 8 assignment line:`, calcLine);
        return calcLine ? [calcLine] : [""];
      }
      return [""];
    }
    
    // For task 9 (string concatenation), look for the print statement with concatenation
    if (taskNum === 9) {
      const printLine = lines.find(line => {
        const trimmed = line.trim();
        return trimmed.startsWith('print') && 
               trimmed.includes('str(celsius)') && 
               trimmed.includes('str(fahrenheit)') &&
               trimmed.includes('+');
      });
      console.log(`Task 9 concatenation line:`, printLine);
      return printLine ? [printLine] : [""];
    }
    
    // For task 10 (error message), look for content in else block
    if (taskNum === 10) {
      // Find the else line first
      const elseIndex = lines.findIndex(line => line.trim().startsWith('else:'));
      if (elseIndex !== -1 && elseIndex < lines.length - 1) {
        // Get the line after else:
        const errorLine = lines[elseIndex + 1].trim();
        console.log(`Task 10 error message line:`, errorLine);
        return errorLine ? [errorLine] : [""];
      }
      return [""];
    }
    
    // For other tasks (1,2,3), use existing logic
    const taskLines = lines.filter(line => {
      const trimmed = line.trim();
      return !trimmed.startsWith('#') && trimmed.length > 0;
    });
    return taskLines;
  }
  else if (questionId === 'q3') {
    const startMarker = `# =====> ${taskNum}.`;
    const endMarker = `# =====> End of Task ${taskNum}`;
    
    const start = code.indexOf(startMarker);
    const end = code.indexOf(endMarker);
    
    if (start === -1 || end === -1) {
      console.log(`Could not find markers for task ${taskNum} in Q3`);
      return null;
    }
    
    // Extract lines between markers and filter out markers themselves
    const section = code.substring(start, end + endMarker.length)
      .split('\n')
      .map(line => line.trim())
      .filter(line => {
        // Keep non-empty lines that aren't markers
        return line && !line.includes('=====>')
          // For multiple-choice tasks, keep commented options
          && (line.startsWith('#') || !line.startsWith('#'));
      });
    
    // Debug logging
    console.log(`Task ${taskNum} extracted section:`, section);
    
    return section;
  }
  else if (questionId === 'q4') {
    const lines = code.split('\n');
    
    // Find the subprogram and main program sections
    const subprogramStart = lines.findIndex(line => line.includes('def hexaLoop'));
    const mainProgramStart = lines.findIndex(line => line.includes('# Main program'));
    
// For subprogram tasks (1-4), look within subprogram section
// For subprogram tasks (1-4), look within subprogram section
if ([1,2,3,4].includes(taskNum)) {
  const subprogramLines = lines.slice(subprogramStart, mainProgramStart)
    .filter(line => !line.trim().startsWith('#') && line.trim().length > 0);
  
  // For Task 3 specifically, find loop contents
  if (taskNum === 3) {
    const loopStart = subprogramLines.findIndex(line => line.includes('for index'));
    if (loopStart !== -1) {
      // Get all calculation lines within the loop
      const calculationLines = subprogramLines
        .slice(loopStart + 1)  // Start after 'for' line
        .filter(line => {
          const trimmed = line.trim();
          return trimmed.includes('=') && 
                 !trimmed.startsWith('return') && 
                 (trimmed.startsWith('digit') ||
                  trimmed.startsWith('value') ||
                  trimmed.startsWith('total') ||
                  trimmed.startsWith('multiplier'));
        });

      // Log what we found for debugging
      console.log('Task 3 calculation lines:', calculationLines);
      
      const digitLine = calculationLines.find(line => line.includes('digit ='));
      const valueLine = calculationLines.find(line => line.includes('value ='));
      const totalLine = calculationLines.find(line => line.includes('total = total +'));  // More specific check
      const multiplierLine = calculationLines.find(line => line.includes('multiplier = multiplier *'));  // More specific check

      // Add task-specific comment to show what's missing/wrong
      let verificationComment = '# Calculations found:\n';
      if (digitLine) verificationComment += '# ✓ digit calculation\n';
      if (valueLine) verificationComment += '# ✓ value calculation\n';
      if (totalLine) verificationComment += '# ✓ total calculation\n';
      if (!totalLine) verificationComment += '# ✗ missing total calculation\n';
      if (multiplierLine) verificationComment += '# ✓ multiplier calculation\n';
      if (!multiplierLine) verificationComment += '# ✗ missing multiplier calculation\n';

      // Add calculation order verification
      const lines = calculationLines.map(line => line.trim());
      const digitIndex = lines.indexOf(digitLine?.trim());
      const valueIndex = lines.indexOf(valueLine?.trim());
      const totalIndex = lines.indexOf(totalLine?.trim());
      const multiplierIndex = lines.indexOf(multiplierLine?.trim());

      if (digitIndex !== -1 && valueIndex !== -1) {
        if (digitIndex < valueIndex) {
          verificationComment += '# ✓ digit before value\n';
        } else {
          verificationComment += '# ✗ digit must come before value\n';
        }
      }

      if (valueIndex !== -1 && totalIndex !== -1) {
        if (valueIndex < totalIndex) {
          verificationComment += '# ✓ value before total\n';
        } else {
          verificationComment += '# ✗ value must come before total\n';
        }
      }

      // Return array with calculations and verification comment
      return [...calculationLines, verificationComment];
    }
    return ["# No calculation lines found in for loop"];
}
  // For Task 4 (return statement), look for return line
  if (taskNum === 4) {
    const returnLine = subprogramLines.find(line => line.trim().startsWith('return'));
    return returnLine ? [returnLine] : ["# No return statement found"];
  }

  // For Tasks 1-2 (initialization and for loop)
  return subprogramLines;
}
    
    // For main program tasks (5-11), look within main program section
    if (mainProgramStart !== -1) {
      const mainProgramLines = lines.slice(mainProgramStart)
        .filter(line => !line.trim().startsWith('#') && line.trim().length > 0);
      
      // Task 7: Subprogram call placement
      if (taskNum === 7) {
        const whileIndex = mainProgramLines.findIndex(line => line.trim().startsWith('while'));
        if (whileIndex !== -1 && whileIndex + 1 < mainProgramLines.length) {
          const firstOperation = mainProgramLines[whileIndex + 1].trim();
          console.log('Task 7 first operation:', firstOperation);
          return [firstOperation];
        }
        return [""];
      }

      // Task 8: Print statement placement
      if (taskNum === 8) {
        const whileIndex = mainProgramLines.findIndex(line => line.trim().startsWith('while'));
        if (whileIndex !== -1 && whileIndex + 2 < mainProgramLines.length) {
          const printOperation = mainProgramLines[whileIndex + 2].trim();
          console.log('Task 8 print operation:', printOperation);
          return [printOperation];
        }
        return [""];
      }

      // Task 9: Input at end of loop
      if (taskNum === 9) {
        const whileIndex = mainProgramLines.findIndex(line => line.trim().startsWith('while'));
        if (whileIndex !== -1) {
          // Find the last indented line in the while block
          let i = whileIndex + 1;
          let lastOperation = '';
          while (i < mainProgramLines.length && mainProgramLines[i].startsWith(' ')) {
            lastOperation = mainProgramLines[i].trim();
            i++;
          }
          console.log('Task 9 last operation:', lastOperation);
          return lastOperation ? [lastOperation] : [""];
        }
        return [""];
      }
      
      return mainProgramLines;
    }
    
    return [""];  // Return empty if sections not found
  }
  return [""];  // Keep existing default return
}; // <-- Added closing brace

/**
 * Prepares the student code as a structured JSON submission
 */
export const prepareSubmission = (code, questionId) => {
  const config = questionConfig[questionId];
  if (!config) return null;
  
  if (questionId === 'q1') {
    const tasks = [];
    for (let i = 1; i <= 10; i++) {
      const section = extractTaskSection(code, i, questionId);
      
      // Special handling for task 6
      if (i === 6) {
        tasks.push({
          taskNumber: i,
          taskType: "Code Completion",
          description: "Logical Operator Usage",
          submittedCode: section[0] || "",
          evaluationNotes: "Task 6 specifically checks for the presence of the word 'and' in the if statement. The presence of 'and' between parentheses is sufficient for this task, even if the bounds are not yet filled in. Original if line shows: " + (section[0] || "no if statement found")
        });
      } else {
        tasks.push({
          taskNumber: i,
          taskType: "Code Completion",
          description: config.descriptions[i],
          submittedCode: section.join('\n') || ""
        });
      }
    }
    return {
      studentSubmission: { tasks },
      originalCode: code,
      taskSpecificInstructions: {
        6: "For task 6 (Logical Operator Usage), award 1 mark if the word 'and' appears in the if statement line, regardless of the surrounding spaces or incomplete bounds. The presence of 'and' between parentheses is sufficient."
      }
    };
  }
  else if (questionId === 'q2') {
    const tasks = [];
    const lines = code.split('\n');
    
    const extractors = {
      3: (lines) => {
        const forLoopLine = lines.find(line => line.trim().startsWith('for meal in'));
        return {
          line: forLoopLine,
          isCorrect: forLoopLine && forLoopLine.trim().endsWith(':')
        };
      },
      4: (lines) => {
        const rangeLine = lines.find(line => line.trim().includes('range('));
        return {
          line: rangeLine,
          isCorrect: rangeLine && rangeLine.includes('range(numMeals)') && !rangeLine.includes('range(numMeals - 1)')
        };
      },
      6: (lines) => {
        const indexLine = lines.find(line => line.includes('mealTable['));
        const isCorrect = indexLine && 
          (indexLine.includes('mealTable[index]') || indexLine.includes('mealTable[index ]')) && 
          !indexLine.includes('index + 1');
        return {
          line: indexLine,
          isCorrect: isCorrect
        };
      },
      7: (lines) => {
        // Find any print line that has either 'name' or 'nmae'
        const printLine = lines.find(line => 
          line.includes('print(') && (line.includes('name') || line.includes('nmae'))
        );
        
        return {
          line: printLine,
          isCorrect: printLine && !printLine.includes('nmae') && printLine.includes('name'),
          requirement: "Fix spelling of 'nmae' to 'name'",
          expectedPattern: "print(name)"
        };
      },
      9: (lines) => {
        const printLine = lines.find(line => 
          line.includes('print') && 
          line.includes('celsius') && 
          line.includes('fahrenheit')
        );
        
        // Check for proper string concatenation with + operators
        const isCorrect = printLine && 
          printLine.includes('str(celsius) +') && 
          printLine.includes('+ str(fahrenheit)') &&
          printLine.includes('"°C is approximately "') &&
          printLine.includes('"°F"');
          
        return {
          line: printLine,
          isCorrect: isCorrect
        };
      }
    };

    for (let i = 1; i <= 8; i++) {
      let taskData;
      
      if (i in extractors) {
        const extracted = extractors[i](lines);
        taskData = {
          taskNumber: i,
          taskType: "Code Correction",
          description: config.descriptions[i],
          submittedCode: extracted.line || "",
          evaluationContext: {
            isCorrect: extracted.isCorrect,
            requirement: getTaskRequirement(i),
            expectedPattern: getExpectedPattern(i)
          }
        };
      } else {
        // Handle other tasks as before
        taskData = {
          taskNumber: i,
          taskType: "Code Correction",
          description: config.descriptions[i],
          submittedCode: extractTaskSection(code, i, questionId).join('\n')
        };
      }
      
      tasks.push(taskData);
    }

    return {
      studentSubmission: { tasks },
      originalCode: code,
      markingInstructions: {
        3: "Task 3 must have exactly ':' at the end of 'for meal in mealTable'",
        4: "Task 4 must have exactly 'range(numMeals)' with no '-1'",
        6: "Task 6 must have exactly 'mealTable[index]' with no '+1'",
        9: "Task 9 must have proper string concatenation with + operators between all parts"
      }
    };
  }
  else if (questionId === 'q3') {
    const tasks = [];
    
    // Process each task from the original code
    for (let taskNum = 1; taskNum <= 15; taskNum++) {
      const taskSection = extractTaskSection(code, taskNum, questionId);
      if (!taskSection) continue;
      
      // Determine task type
      const isCompletion = [1, 2, 3, 8].includes(taskNum);
      
      tasks.push({
        taskNumber: taskNum,
        taskType: isCompletion ? "Code Completion" : "Multiple-Choice Uncommenting",
        description: TASK_DESCRIPTIONS[taskNum],
        submittedCode: isCompletion ? taskSection.join('\n') : { options: taskSection }
      });
    }

    return {
      studentSubmission: {
        tasks: tasks
      },
      originalCode: code
    };
  }
  else if (questionId === 'q4') {
    const tasks = [];
    for (let i = 1; i <= questionConfig[questionId].totalTasks; i++) {
      const section = extractTaskSection(code, i, questionId);
      tasks.push({
        taskNumber: i,
        taskType: questionConfig[questionId].taskTypes[i],
        description: questionConfig[questionId].descriptions[i],
        submittedCode: section.join('\n') || ""
      });
    }
    return {
      studentSubmission: { tasks },
      originalCode: code,
      taskSpecificInstructions: {
        3: "For task 3 (Order of calculations), check that all calculations inside the loop are in correct sequence",
        9: "For task 9 (User input at end of loop), ensure input is the last operation in the loop",
        11: "For task 11 (Code indentation), check overall code structure and consistent indentation"
      }
    };
  }
  else if (questionId === 'q5') {
    const tasks = [];
    for (let i = 1; i <= questionConfig[questionId].totalTasks; i++) {
      const section = extractTaskSection(code, i, questionId);
      
      // Special handling for Task 2 which returns an object with verification data
      if (i === 2 && section.verificationData) {
        tasks.push({
          taskNumber: i,
          taskType: questionConfig[questionId].taskTypes[i],
          description: questionConfig[questionId].descriptions[i],
          submittedCode: section.submittedCode,
          verificationData: section.verificationData
        });
      } else {
        tasks.push({
          taskNumber: i,
          taskType: questionConfig[questionId].taskTypes[i],
          description: questionConfig[questionId].descriptions[i],
          submittedCode: Array.isArray(section) ? section.join('\n') : ""
        });
      }
    }
    return {
      studentSubmission: { tasks },
      //originalCode: questionConfig[questionId].initialCode,
      taskSpecificInstructions: {
        1: "Check if ANY parameter name is different from original (bookTitle, authorLastName, pubYear). At least one must be different.",
        2: "For task 2, check that ALL THREE parameter names have different spelling from their original counterparts.",
        3: "Check if new names are meaningful AND different from global variables. Parameters should not have same names as globals.",
        4: "Check for correct use of ord() function",
        5: "Verify header procedure is defined with 'def' and no parameters",
        6: "Header message must contain words related to library system (e.g., 'library', 'system', 'book', 'identifier')",
        7: "Check if 'displayHeader()' is called before any input() statements. Current call location: before first input statement",
        8: "For task 8, check that BOTH bookTitle AND authorLastName are converted to uppercase. Must have .upper() for BOTH variables. If either is missing, award 0 marks.",
        9: "Verify complete year validation",
        10: "Assess overall solution design (0-3)",
        11: "Assess overall functionality (0-3)"
      }
    };
  }
  else if (questionId === 'q6') {
    return {
      studentSubmission: {
        tasks: [{
          taskNumber: 1,
          taskType: "Complete Solution",
          description: "Implement student authentication system",
          submittedCode: code  // Pass the entire code unchanged
        }]
      },
      originalCode: code,
      taskSpecificInstructions: {
        1: "Check for two input statements with appropriate prompts",
        2: "Check for blank input validation using appropriate methods",
        3: "Check for loop traversing userTable array",
        4: "Check for logical operators in conditions",
        5: "Check for correct array field access [0] and [1]",
        6: "Check for three distinct output messages: Welcome, Incorrect password, User not found",
        7: "Assess solution design using levels criteria (0-3)",
        8: "Assess programming practices using levels criteria (0-3)",
        9: "Assess functionality using levels criteria (0-3)"
      }
    };
  }
};

// Helper function to get task descriptions
const getTaskDescription = (taskNum) => {
  const descriptions = {
    1: "Add the correct input file name (Spades.txt)",
    2: "Add the correct extension to this file name (Plants.txt)",
    3: "Complete the line with the correct variable name for the array of plants",
    4: "Choose the correct value to initialize the 'total' variable",
    // ... add descriptions for all tasks
  };
  return descriptions[taskNum] || `Task ${taskNum}`;
};

export const evaluateCode = async (submission, questionPrompt, questionId) => {
  try {
    const model = questionId === 'q3' ? 'gpt-4o' : 'gpt-4o-mini';
    
    console.log('Starting evaluation with:', {
      submissionLength: JSON.stringify(submission).length,
      questionId,
      model
    });

    // Log the student submission separately
    console.log('Student submission:', JSON.stringify(submission, null, 2));

    const messages = [
      getSystemPrompt(questionId),
      {
        role: 'user',
        content: `${questionPrompt}

The student's code submission is formatted as JSON for evaluation:

<student_response>
${JSON.stringify(submission, null, 2)}
</student_response>

Return ONLY a valid JSON object matching the specified structure. Do not include any markdown formatting or code block markers.`
      }
    ];

    // Log each part of the prompt separately
    console.log('System prompt:', messages[0].content);
    console.log('Question prompt:', questionPrompt);
    console.log('Complete message array:', JSON.stringify(messages, null, 2));
    
    console.log('Sending prompt to API...');
    
    const response = await axios.post(API_URL, {
      model: model,
      messages: messages,
      max_tokens: 4000,
      temperature: 0.1
    }, { headers });

    const content = response.data.choices[0].message.content;
    console.log('Raw AI response:', content);

    try {
      const cleanedContent = cleanAIResponse(content);
      console.log('Cleaned response:', cleanedContent);
      const parsedResponse = JSON.parse(cleanedContent);
      console.log('Successfully parsed response');
      return parsedResponse;
    } catch (parseError) {
      console.error('JSON Parse Error:', {
        error: parseError.message,
        position: parseError.position,
        content: content
      });
      throw new Error(`Failed to parse AI response: ${parseError.message}`);
    }

  } catch (error) {
    console.error('Error in evaluateCode:', {
      error: error.message,
      questionId
    });
    throw error;
  }
};

const getSystemPrompt = (questionId) => {
  if (questionId === 'q6') {
    return {
      role: "system",
      content: `You are a code evaluation AI that ONLY responds with valid JSON objects. 
Your responses must EXACTLY match this structure:
{
  "totalMark": X,
  "technicalImplementation": {
    "total": x,
    "criteria": [
      {
        "criterionNumber": "1.1",
        "description": "Description of criterion 1.1",
        "studentCode": "[Student’s code here]",
        "markAwarded": 0 or 1,
        "evidence": "Specific evidence from student’s code"
      },
      {
        "criterionNumber": "1.2",
        "description": "Description of criterion 1.2",
        "studentCode": "[Student’s code here]",
        "markAwarded": 0 or 1,
        "evidence": "Specific evidence from student’s code"
      },
      ...
    ]
  },
  "levelsBasedAssessment": {
    "total": x,
    "solutionDesign": {
      "levelAwarded": 0, 1, 2, or 3,
      "evidence": [
        "Specific example from code showing Solution Design criteria met"
      ]
    },
    "goodProgrammingPractice": {
      "levelAwarded": 0, 1, 2, or 3,
      "evidence": [
        "Specific example from code showing Good Programming Practice criteria met"
      ]
    },
    "functionality": {
      "levelAwarded": 0, 1, 2, or 3,
      "evidence": [
        "Specific example from code showing Functionality criteria met"
      ]
    }
  },
  "suggestions": [
    "Provide clear, actionable suggestions for each area where the student lost marks.",
    "For example: Ensure you handle blank inputs properly in the authentication process."
  ],
  "overallComment": "A brief summary of the student’s overall performance, including strengths and areas for improvement."
}`
    };
  }
  const baseStructure = `You are a code evaluation AI that ONLY responds with valid JSON objects. 
Your responses must EXACTLY match this structure:
{
  "totalMark": number,
  "taskEvaluations": [
    {
      "taskNumber": number,
      "taskDescription": string,
      "taskType": string,
      "lineStatus": "Code Correct|Code Incorrect",
      "markAwarded": number,
      "feedback": string
    }
  ],
  "suggestions": string[],
  "overallComment": string
}`;

  const questionRules = {
    'q1': `
IMPORTANT MARKING RULES:
1. For Task 2 (User Input Prompt):
   - Award 1 mark if input() is used with ANY temperature-related prompt
   - Do NOT consider int() conversion when marking Task 2

2. For Task 3 (Integer Conversion):
   - Award 1 mark if int() is used to convert the input
   - This is evaluated separately from Task 2

3. For Task 6 (Logical Operator Usage):
   - Award 1 mark ONLY if the EXACT WORD 'and' appears between the two conditions
   - Do NOT award marks for:
     * Spaces between conditions: if ((-10 <= celsius)  (celsius <= 35))  // 0 marks
     * Other operators like &, &&: if ((-10 <= celsius) && (celsius <= 35))  // 0 marks
     * Different capitalization: if ((-10 <= celsius) AND (celsius <= 35))  // 0 marks
   - Correct example: if ((-10 <= celsius) and (celsius <= 35))  // 1 mark

4. For Task 8 (Assignment Operator Usage):
   - Award 1 mark if fahrenheit is followed by the assignment operator '='
   - Do NOT consider what follows the '=' when marking Task 8

5. For Task 9 (String Concatenation):
   - Award 1 mark if ALL of these conditions are met:
     * Uses str() for both celsius and fahrenheit variables
     * Uses + operator for concatenation
     * Contains "°C is approximately" text
     * Contains "°F" text
   - Example of correct code: print(str(celsius) + "°C is approximately " + str(fahrenheit) + "°F")
   - Do NOT deduct marks for:
     * Extra spaces around the + operator
     * Different quote types ("" vs '')
     * Additional parentheses
   - Award 0 marks if:
     * Missing str() for either variable
     * Missing any + operator
     * Missing or incorrect text messages
     * Using , instead of + for concatenation

Tasks 2 and 3 must be evaluated independently.
Task 8 must be evaluated independently of Task 7.`,

    'q2': `
IMPORTANT MARKING RULES:
1. For Task 1 (Library Import):
   - Award 1 mark ONLY if 'rundom' is changed to 'random'
   - Must be exactly 'import random'
   - No mark if unchanged or changed incorrectly

2. For Task 2 (Array Initialization):
   - Award 1 mark ONLY if 'burger' is enclosed in quotes
   - Must be exactly: mealTable = ["pasta", "salad", "burger", "stir-fry", "tacos", "soup"]
   - No mark if unchanged or changed incorrectly

3. For Task 3 (For Loop):
   - Award 1 mark ONLY if colon is added after 'for meal in mealTable'
   - Must be exactly: 'for meal in mealTable:'
   - No mark if unchanged or colon missing

4. For Task 4 (Range):
   - Award 1 mark ONLY if '-1' is removed from 'range(numMeals - 1)'
   - Must be exactly: 'range(numMeals)'
   - No mark if unchanged or changed incorrectly

5. For Task 5 (Random):
   - Award 1 mark ONLY if 'random.' is completed with 'randint(0, 5)'
   - Must be exactly: 'random.randint(0, 5)'
   - No mark if unchanged or incomplete

6. For Task 6 (Index):
   - Award 1 mark ONLY if '+1' is removed from 'mealTable[index + 1]'
   - Must be exactly: 'mealTable[index]'
   - No mark if unchanged or still contains +1

7. For Task 7 (Name):
   - Award 1 mark ONLY if 'nmae' is corrected to 'name'
   - Must be exactly: 'print(name)'
   - No mark if unchanged or still misspelled

8. For Task 8 (Whitespace):
   - Award 1 mark ONLY if consistent indentation is used throughout
   - Must have proper indentation after both for loops
   - No mark if any indentation is inconsistent

Each task must be marked independently.
No partial marks - each task is worth exactly 1 mark.
Code must match requirements exactly to receive marks.`,

    'q3': `
IMPORTANT MARKING RULES:
1. For Code Completion Tasks (1-3, 8):
   - Award 1 mark for exact matches only
   - Variable names must match specification
   - File extensions must be correct

2. For Multiple-Choice Tasks (4-7, 9-15):
   - Award 1 mark only if exactly one line is uncommented
   - No marks if multiple lines are uncommented
   - No marks if all lines remain commented

3. For Task 8 (Array Access):
   - Must use correct array name and index
   - Syntax must be exact`,

    'q4': `
Important Marking Rules:

1. Subprogram Marking (8 marks):

   a) Initialization of variables (2 marks):
      • Award marks for variables initialized together before calculations
      • One mark for any two variables initialized together, up to maximum of 2
      • Variables to check: multiplier, total, digit, value

   b) Placement of for loop (1 mark):
      • Award 1 mark if for loop follows variable initialization
      • No marks if placed elsewhere

   c) Order of calculations (4 marks):
      • Each calculation in correct position earns 1 mark independently:
        1. digit calculation: "digit = pHexa[index]"
           - 1 mark if it appears first in calculations
           - 0 marks if missing or not first
        
        2. value calculation: "value = multiplier * int(digit, 16)"
           - 1 mark if it appears after digit calculation
           - 0 marks if missing or in wrong position
        
        3. total calculation: "total = total + value"
           - 1 mark if it appears after value calculation
           - 0 marks if missing or in wrong position
        
        4. multiplier calculation: "multiplier = multiplier * 16"
           - 1 mark if it appears after value calculation
           - 0 marks if missing or in wrong position
      
      • Missing calculations get 0 marks but do not affect marks for other calculations
      • Both total and multiplier can come in either order after value
      
      Examples:
      3 marks awarded for:
          digit = pHexa[index]
          value = multiplier * int(digit, 16)
          total = total + value
          [multiplier missing but other calculations correct]

      2 marks awarded for:
          digit = pHexa[index]              # 1 mark (first)
          value = multiplier * int(digit, 16) # 1 mark (after digit)
          multiplier = multiplier * 16      # 0 mark (before total)
          total = total + value            # 0 mark (wrong position)
        
   d) Return statement placement (1 mark):
      • Must be last line in subprogram
      • No marks if not last line

2. Main Program Marking (5 marks):

   CRITICAL: For Tasks 5 and 6, follow these exact checking steps:

   a) First input statement (Task 5 - 1 mark):
      MUST check these conditions IN ORDER:
      1. Count the number of input statements BEFORE the while loop
         • If more than one input found: 0 marks
         • If no input found: 0 marks
      2. Check for input statements INSIDE the while loop
         • If first input appears inside loop: 0 marks
      3. Compare input statements
         • If original duplicated inputs remain unchanged: 0 marks
      Award 1 mark ONLY if all conditions are met

   b) While loop placement (Task 6 - 1 mark):
      MUST check these conditions IN ORDER:
      1. Locate the while loop line: "while hexa != "":" 
      2. Look at the EXACT line before it
         • If it's NOT an input statement: 0 marks
         • If there's ANY code between input and while: 0 marks
      3. Check the input statement
         • If it's not the first input of the program: 0 marks
      Award 1 mark ONLY if all conditions are met

   Example of CORRECT code (1 mark for each task):
   hexa = input("Enter a hexadecimal pattern (empty to exit): ")
   while hexa != "":
       [rest of loop code]

   Examples of INCORRECT code (0 marks):
   1. Multiple inputs before while:
      hexa = input()
      hexa = input()  # 0 marks for Task 5
      while hexa != "":

   2. Code between input and while:
      hexa = input()
      denary = hexaLoop(hexa)  # 0 marks for Task 6
      while hexa != "":

   3. Input inside while:
      while hexa != "":
          hexa = input()  # 0 marks for Task 5
   
   c) Subprogram call (Task 7 - 1 mark):
      MUST check these conditions IN ORDER:
      1. Locate the while loop line
      2. Check the FIRST line after while loop
         • Must contain 'denary = hexaLoop(hexa)'
         • Must be indented within while block
      Award 1 mark if both conditions are met

      Example of CORRECT code (1 mark):
      while hexa != "":
          denary = hexaLoop(hexa)    # 1 mark - first operation

      Example of INCORRECT code (0 marks):
      while hexa != "":
          print(hexa)              # 0 marks - wrong first operation
          denary = hexaLoop(hexa)  # 0 marks - not first operation
   
   d) Remaining main program tasks...
   [rest of marking scheme continues as before]

Task 10 (Test data handling - 1 mark):
MUST evaluate the code in this EXACT sequence:

1. First, check program structure:
   a) Must find exactly ONE input statement BEFORE while loop
   b) Must find while loop checking "hexa != """
   c) Must find exactly ONE input statement as LAST operation in loop
   If ANY of these are missing: 0 marks

2. Then, check execution order:
   a) Program must take first input BEFORE while loop check
   b) While loop must use that input value for its condition
   c) Next input must be last operation in loop
   If order is wrong: 0 marks

3. Common incorrect patterns that MUST get 0 marks:
   • While loop before first input:
     while (hexa != ""):
         hexa = input()    # WRONG - loop will exit immediately

   • Input outside loop:
     hexa = input()
     while hexa != "":
         [code]
     hexa = input()        # WRONG - input not in loop

   • Multiple inputs before loop:
     hexa = input()
     hexa = input()        # WRONG - overwrites first input
     while hexa != "":

4. Award 1 mark ONLY if ALL these conditions are met:
   • ONE input before while
   • While loop checks that input
   • ONE input at end of loop
   • Correct execution order

Example of CORRECT code (1 mark):
hexa = input("...")        # First input
while hexa != "":          # Check that input
    [process hexa]         # Use the input
    hexa = input("...")    # Get next input as last operation
`,
    'q5': `
IMPORTANT MARKING RULES:

1. Parameter Name Changes (Tasks 1-3):
   
   Task 1 (1 mark):
   • Award mark if ANY parameter name is STRICTLY different from original
   • Original names: bookTitle, authorLastName, pubYear
   • Example: bTitle is different from bookTitle, so award mark
   
   Task 2 (1 mark):
   • Award mark if ALL THREE parameter names are different strings from originals
   • bTitle is different from bookTitle
   • authLName is different from authorLastName
   • pYear is different from pubYear
   • IMPORTANT: Similar names (like pYear vs pubYear) count as different!
   
   Task 3 (1 mark):
   • Award mark if changed names are meaningful
   • Similar but clear names are good (e.g., pYear for pubYear)
   • Names should relate to their purpose
   
   Example of correct parameter names that should get full marks:
   def makeIdentifier(bTitle, authLName, pYear):
     
Task 8 (Uppercase Conversion - 1 mark):
• Must check BOTH input variables:
  - bookTitle must be converted to uppercase
  - authorLastName must be converted to uppercase
• Acceptable patterns:
  - Direct on input: variable = input().upper()
  - After input: variable = variable.upper()
• Award 0 marks if:
  - Only one variable is converted
  - Either variable is missing .upper()

6. Overall Assessment:

   [Task 10 rules as previously defined...]

   Task 11 - Overall Functionality (0-3 marks):
   
   Technical Requirements:
   • Program must translate (compile) without errors
   • Program must run without runtime errors
   • Welcome message must be displayed
   • Error message must be shown for invalid publication year
   • Must fully meet all requirements

   Marking Levels:
   0 marks: No rewardable material

   1 mark: Basic functionality
   • Program partially works
   • Some components function correctly
   • Some clear and appropriate logic
   • Limited use of appropriate variables/data structures
   • Limited use of appropriate programming constructs

   2 marks: Most functionality present
   • Program mostly works as intended
   • Most components function correctly
   • Mostly clear and appropriate logic
   • Mostly appropriate variables/data structures
   • Mostly appropriate programming constructs

   3 marks: Complete functionality
   • Program works perfectly as specified
   • All components function correctly
   • Clear and appropriate logic throughout
   • Appropriate variables/data structures throughout
   • Accurate and appropriate programming constructs

   Note: Award maximum of 3 marks
   Note: Program must meet all Technical Requirements for full marks`,
    'q6': `
IMPORTANT MARKING RULES:

1. Technical Implementation (6 marks):

   Task 1 - Taking Two String Inputs (1 mark):
   • Must have TWO input statements
   • Must prompt for both student ID and access code
   • Example: student_id = input("Enter your student ID: ")

   Task 2 - Checking for Blank Input (1 mark):
   • Must check BOTH inputs are not blank
   • Acceptable methods:
     - if student_id == "" or access_code == ""
     - if len(student_id) == 0
     - if not student_id
   
   Task 3 - Array Traversal (1 mark):
   • Must use loop to search userTable
   • Example: for user in userTable:
   
   Task 4 - Logical Operators (1 mark):
   • Must use compound conditions with and/or/not
   • Examples:
     - if student_id == "" or access_code == ""
     - while not found and index < len(userTable):
   
   Task 5 - Array Access (1 mark):
   • Must correctly access array fields [0] and [1]
   • Example: if user[0] == student_id:
   
   Task 6 - State Handling (1 mark):
   • Must implement ALL three outcomes:
     - "Welcome" for correct credentials
     - "Incorrect password" for wrong password
     - "User not found" for unknown ID

2. Levels-Based Assessment (9 marks):

   Solution Design (3 marks):
   Level 0: No rewardable material
   Level 1: Limited decomposition, some logic appropriate
   Level 2: Most components present, mostly clear logic
   Level 3: Clear decomposition, all logic appropriate

   Good Programming Practice (3 marks):
   Level 0: No rewardable material
   Level 1: Limited readability, some meaningful names
   Level 2: Mostly readable, appropriate comments
   Level 3: Consistent style, effective commenting

   Functionality (3 marks):
   Level 0: No rewardable material
   Level 1: Limited functionality, some requirements met
   Level 2: Mostly functional, minor issues
   Level 3: Fully functional, meets all requirements

IMPORTANT NOTES:
• Award NO marks for unchanged template code
• Each technical criterion must be met EXACTLY
• Levels can only be awarded if ALL criteria for that level are met
• Provide specific code evidence for EVERY mark awarded`,
  };

  if (questionId === 'q2') {
    return {
      role: "system",
      content: `${baseStructure}
IMPORTANT: Tasks 4 and 6 must be evaluated using their evaluationContext:
- If evaluationContext.isCorrect is true, the task must be marked as correct
- For Task 4, award 1 mark if range(numMeals) is used with no '-1'
- For Task 6, award 1 mark if mealTable[index] is used with no '+1'
- Each task's evaluationContext contains the final verification of correctness

IMPORTANT MARKING RULES:
1. For Task 1 (Library Import):
   - Award 1 mark ONLY if 'rundom' is changed to 'random'
   - Must be exactly 'import random'
   - No mark if unchanged or changed incorrectly

2. For Task 2 (Array Initialization):
   - Award 1 mark ONLY if 'burger' is enclosed in quotes
   - Must be exactly: mealTable = ["pasta", "salad", "burger", "stir-fry", "tacos", "soup"]
   - No mark if unchanged or changed incorrectly

3. For Task 3 (For Loop):
   - Award 1 mark ONLY if colon is added after 'for meal in mealTable'
   - Must be exactly: 'for meal in mealTable:'
   - No mark if unchanged or colon missing

4. For Task 4 (Range):
   - Check evaluationContext.isCorrect for final verification
   - Award 1 mark if evaluationContext indicates correct implementation
   - Must be exactly 'range(numMeals)' with no '-1'

5. For Task 5 (Random):
   - Award 1 mark ONLY if 'random.' is completed with 'randint(0, 5)'
   - Must be exactly: 'random.randint(0, 5)'
   - No mark if unchanged or incomplete

6. For Task 6 (Index):
   - Check evaluationContext.isCorrect for final verification
   - Award 1 mark if evaluationContext indicates correct implementation
   - Must be exactly 'mealTable[index]' with no '+1'

7. For Task 7 (Name):
   - Award 1 mark ONLY if 'nmae' is corrected to 'name'
   - Must be exactly: 'print(name)'
   - No mark if unchanged or still misspelled

8. For Task 8 (Whitespace):
   - Award 1 mark ONLY if consistent indentation is used throughout
   - Must have proper indentation after both for loops
   - No mark if any indentation is inconsistent

CRITICAL: For any task that includes evaluationContext in the submission:
- The evaluationContext.isCorrect value MUST be used as the primary determinant
- The other rules serve as explanation/documentation only
- A true evaluationContext.isCorrect MUST result in a mark being awarded

Each task must be marked independently.
No partial marks - each task is worth exactly 1 mark.
Code must match requirements exactly to receive marks.`
    };
  }

  return {
    role: "system",
    content: `${baseStructure}${questionRules[questionId] || ''}`
  };
};

// Helper functions for Q2
const getTaskRequirement = (taskNum) => {
  const requirements = {
    4: "Remove '-1' from range(numMeals - 1)",
    6: "Remove '+1' from mealTable[index + 1]",
    7: "Fix spelling of 'nmae' to 'name'",
    9: "String concatenation using + operators between all parts"
  };
  return requirements[taskNum] || '';
};

const getExpectedPattern = (taskNum) => {
  const patterns = {
    4: "range(numMeals)",
    6: "mealTable[index]",
    7: "print(name)",
    9: 'print(str(celsius) + "°C is approximately " + str(fahrenheit) + "°F")'
  };
  return patterns[taskNum] || '';
};

function processAIResponse(response) {
    // ... existing validation ...

    // Make sure Task 4 is included in the evaluation
    const requiredTasks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    
    // Ensure all required tasks are present
    requiredTasks.forEach(taskNumber => {
        if (!response.taskEvaluations.some(task => task.taskNumber === taskNumber)) {
            throw new Error(`Missing evaluation for Task ${taskNumber}`);
        }
    });

    // ... rest of processing ...
}

const formatQ6Feedback = (feedback) => {
  try {
    // Technical Implementation table
    let technicalTable = `
## Technical Implementation (6 marks)

| Criterion | Description | Mark | Evidence |
|-----------|-------------|------|----------|
`;
    
    // Ensure we're using the correct property names from the AI response
    feedback.technicalImplementation.criteria.forEach(criterion => {
      technicalTable += `| ${criterion.criterionNumber} | ${criterion.description} | ${criterion.markAwarded}/1 | ${criterion.evidence} |\n`;
    });

    // Levels-Based Assessment table
    let levelsTable = `
## Levels-Based Assessment (9 marks)

| Category | Level | Evidence |
|----------|-------|----------|
| Solution Design | ${feedback.levelsBasedAssessment.solutionDesign.levelAwarded}/3 | ${feedback.levelsBasedAssessment.solutionDesign.evidence.join('; ')} |
| Good Programming Practice | ${feedback.levelsBasedAssessment.goodProgrammingPractice.levelAwarded}/3 | ${feedback.levelsBasedAssessment.goodProgrammingPractice.evidence.join('; ')} |
| Functionality | ${feedback.levelsBasedAssessment.functionality.levelAwarded}/3 | ${feedback.levelsBasedAssessment.functionality.evidence.join('; ')} |
`;

    // Summary section
    let summary = `
## Summary

Total Mark: ${feedback.totalMark}/15

### Overall Comment
${feedback.overallComment}

### Suggestions for Improvement
${feedback.suggestions.map(s => '- ' + s).join('\n')}
`;

    return technicalTable + levelsTable + summary;
  } catch (error) {
    console.error('Error formatting Q6 feedback:', error);
    throw error; // Throw the error to handle it in processResponse
  }
};

const processResponse = async (response, questionId) => {
  if (questionId === 'q6') {
    try {
      // Log the response for debugging
      console.log('Processing Q6 response:', response);
      
      const formattedFeedback = formatQ6Feedback(response);
      console.log('Formatted feedback:', formattedFeedback);
      
      // Convert to HTML using the imported marked function
      const htmlContent = marked(formattedFeedback);
      console.log('HTML content:', htmlContent);
      
      return htmlContent;
    } catch (error) {
      console.error('Error in processResponse:', error);
      // Use the imported marked function here too
      return marked(`
## Error Processing Feedback

There was an error processing the feedback. Please try again.

Technical details: ${error.message}
`);
    }
  }
  // ... rest of function
};

const evaluateSubmission = async (submission) => {
  // ... existing validation ...

  // Make sure Task 4 is included in the evaluation
  const requiredTasks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  
  // Ensure all required tasks are present
  requiredTasks.forEach(taskNumber => {
      if (!submission.taskEvaluations.some(task => task.taskNumber === taskNumber)) {
          throw new Error(`Missing evaluation for Task ${taskNumber}`);
      }
  });

  // ... rest of processing ...
};